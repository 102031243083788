<template>
  <div class="form--group row" v-if="order">
    <div class="col-12 col-lg-3"></div>
    <div class="col-12 col-lg-6">
      <div class="mb-4" style="font-size: 24px; font-weight: bold">Info Order</div>
      <div class="row">
        <div class="col-4">{{ $t('refund.orderNumber') }}</div>
        <div class="text-right col-8">
          {{ order.order_number }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.productName') }}</div>
        <div class="text-right col-8">
          {{ order.product_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.agentName') }}</div>
        <div class="text-right col-8">
          {{ order.agent_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.agentEmail') }}</div>
        <div class="text-right col-8">
          {{ order.agent_email }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.agentPhone') }}</div>
        <div class="text-right col-8">
          {{ order.agent_phone }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.salesName') }}</div>
        <div class="text-right col-8">
          {{ order.sales_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.paidAmount') }}</div>
        <div class="text-right col-8">
          {{ currencyFormat(order.paid_amount) }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.paymentVia') }}</div>
        <div class="text-right col-8">
          {{ order.payment_via }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{ $t('refund.createdAt') }}</div>
        <div class="text-right col-8">
          {{
            order.created_at != null ? format(new Date(order.created_at), 'dd MMMM yyyy HH:mm') : ''
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  name: 'order-info',
  mixins: [HelperMixin],
  props: ['order'],
  components: {},
  data: () => ({
    format,
  }),
};
</script>

<style scoped></style>
